<template>
  <v-container>
    <div class="ml-2">
      <PageTitle text="Attendants To Pump Assignation/Activities" />
    </div>
    <div class="buttonText ml-1" id="result">
      <div class="d-flex flex-row flex-grow-1 flex-fill justify-space-between">
        <v-btn
          v-ripple="{ class: 'primary--text' }"
          color="primary"
          class="font ml-2 mt-3 white--text"
          style="font-size: 12px"
          @click="openAddDialog"
        >
          Assign Attendants To Pumps
          <v-icon size="20" color="white">mdi-plus</v-icon>
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              @click.stop="getAllPumpAttendants(paginate)"
              small
              v-on="on"
              icon
              fab
              color="primary"
              class="mr-4 mt-1"
            >
              <i class="material-icons-outlined">refresh</i>
            </v-btn>
          </template>
          <span class=" font font-weight-medium font-size-sm"
            >Refresh Page</span
          >
        </v-tooltip>
      </div>

      <h4
        style="margin-top: 20px; margin-left: 10px"
        class="font blue-grey--text"
      >
        All Pump To Attendant Records
      </h4>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <div
        v-if="isLoading"
        class="d-flex flex-column flex-grow-1 justify-center align-center"
      >
        <v-progress-circular indeterminate color="primary" />
        <h4
          class="font font-weight-medium font-size-sm "
          style="padding-top: 1.1rem"
        >
          Loading list of activities with pump attendants
        </h4>
      </div>
      <template v-else>
        <v-col>
          <v-row v-if="assignedPumpAttendants.length > 0">
            <div class="d-flex flex-row flex-grow-1 justify-end py-2">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    elevation="2"
                    v-show="selectedPumpAssignedAttendants.length > 0"
                    fixed
                    color="primary"
                    right
                    class="mr-4 mb-5"
                    bottom
                    fab
                    @click.stop="confirm = true"
                    v-on="on"
                  >
                    <i class="material-icons-outlined">delete_sweep</i>
                  </v-btn>
                </template>
                <span class=" font font-weight-medium font-size-sm"
                  >Delete
                  {{ selectedPumpAssignedAttendants.length }} item(s)</span
                >
              </v-tooltip>
            </div>
            <v-col
              v-for="(pumpAttendant, index) in assignedPumpAttendants"
              :key="index"
              cols="12"
              sm="12"
            >
              <template>
                <div>
                  <div
                    class="d-flex flex-row flex-grow-1 justify-space-between"
                  >
                    <h2
                      class="font font-weight-medium font-size-md text-capitalize mx-2 my-2 font-italic"
                    >
                      {{ pumpAttendant.key | format }}
                    </h2>
                  </div>
                  <v-simple-table
                    class="font font-weight-medium box-shadow-light"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left"></th>
                          <th class="text-left">
                            Pump Attendant Name
                          </th>
                          <th class="text-left">
                            Pump
                          </th>
                          <th class="text-left">
                            Number Of Nozzles
                          </th>
                          <th class="text-left">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in pumpAttendant.values"
                          :key="index"
                        >
                          <td>
                            <v-checkbox
                              :value="item.id"
                              hide-details
                              v-model="selectedPumpAssignedAttendants"
                              class="mt-n1"
                            />
                          </td>
                          <td>{{ item.attendantId.name }}</td>
                          <td>{{ item.pumpId.name }}</td>
                          <td>{{ item.pumpId.numberOfNozzles }}</td>
                          <td>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  icon
                                  small
                                  :to="{
                                    name:
                                      'master.dispensers.activities.assignations',
                                    params: {
                                      id: item.id
                                    }
                                  }"
                                >
                                  <i
                                    class="material-icons-outlined font-size-sm primary--text"
                                    >assignment_turned_in
                                  </i>
                                </v-btn>
                              </template>
                              <span
                                class=" font font-weight-medium font-size-sm"
                                >Edit Assignation</span
                              >
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </template>
            </v-col>
            <div class="d-flex flex-row flex-grow-1 justify-center">
              <v-pagination
                circle
                color="primary"
                v-model="paginate.page"
                :length="paginate.pages"
                :total-visible="15"
              />
            </div>
          </v-row>
          <v-row
            v-else
            align="center"
            justify="center"
            style="min-height: 500px;"
          >
            <div
              class="d-flex flex-column flex-grow-1 "
              style="align-items:center;"
            >
              <img
                src="@/assets/settings.svg"
                style="width: 180px;"
                class="mb-3"
              />
              <span class="font font-weight-medium font-size-md pt-3"
                >No <kbd class="ml-1">attendant</kbd> has been assigned to a
                pump right now.</span
              >
              <span class="font font-weight-medium font-size-sm pt-2"
                >All assigned attendants to pumps will show up here !!!</span
              >
            </div>
          </v-row>
        </v-col>
      </template>
    </v-row>
    <v-dialog v-model="dialog1" width="400px">
      <v-form ref="form">
        <v-card>
          <v-card-title>
            <span class="font-weight-bold blue-grey--text"
              >Assign Attendants To Pumps</span
            >
            <v-spacer></v-spacer>
            <v-progress-linear
              v-show="progress"
              class="mx-6"
              indeterminate
              color="cyan"
            ></v-progress-linear>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" class="px-3">
              <v-col>
                <v-row>
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    :nudge-left="10"
                    :nudge-top="30"
                    offset-y
                    min-width="auto"
                    class="ft font-weight-medium  "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        v-model="attendantToPump.date"
                        outlined
                        label="Choose a Date"
                        :error="validateDate(attendantToPump.date)"
                        :error-messages="
                          validateDate(attendantToPump.date)
                            ? 'Date cannot be in the future'
                            : ''
                        "
                      />
                    </template>
                    <v-date-picker
                      class="font font-weight-medium  "
                      v-model="attendantToPump.date"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-row>
                <v-row>
                  <v-autocomplete
                    :items="pumps"
                    item-value="id"
                    item-text="name"
                    v-model="attendantToPump.pumpId"
                    outlined
                    label="Choose a pump"
                  />
                </v-row>
                <v-row>
                  <v-autocomplete
                    :items="pumpAttendants"
                    item-value="id"
                    v-model="attendantToPump.attendantId"
                    item-text="name"
                    label="Select one or more pump attendants"
                    small-chips
                    color="blue"
                    outlined
                  />
                </v-row>
              </v-col>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="dialog1 = false" class="mr-2 text-capitalize">
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              :disabled="validateDate(attendantToPump.date)"
              @click.stop="assignAttendantToPumps"
              class="text-capitalize"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
    <ConfirmationDialog
      title="Delete Assigned Pumps"
      :state="confirm"
      :loading="isSaveLoading"
      @save="deleteAssignedPumpToAttendant"
      @close="confirm = false"
      body="This action will permanently remove assigned pumps to attendants from the system"
    />
  </v-container>
</template>

<script>
import { Api } from "@/api/Api";
import { from } from "rxjs";
import { groupBy, reduce, mergeMap, toArray } from "rxjs/operators";
import PageTitle from "@/components/PageTitle";
// import { groupBy } from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import DialogMixins from "../../mixins/DialogMixins";

export default {
  name: "Activities",
  components: {
    PageTitle,
    SnackBar: () => import(`../../components/SnackBar`),
    ConfirmationDialog: () =>
      import(`../sales/components/ConfirmationDialog.vue`)
  },
  filters: {
    format(value) {
      return moment(value).format("Do MMMM, YYYY");
    }
  },
  computed: {
    ...mapGetters({
      assignedPumps: "pumps/getAlreadyAssignedPumps"
    })
  },
  data() {
    return {
      confirm: false,
      showSnackBar: false,
      status: "success",
      message: "",
      dialog1: false,
      dialogUpdate: false,
      progress: false,
      isLoading: false,
      assignedPumpAttendants: [],
      date: "",
      menu: false,
      attendantToPump: {
        pumpId: "",
        date: "",
        attendantId: []
      },
      isSaveLoading: false,
      isPageLoading: false,
      pumpAttendants: [],
      pumps: [],
      copyPumpAttendants: [],
      copyPumps: [],
      headers: [
        {
          text: "",
          value: "avatar"
        },
        {
          text: "Attendant",
          value: "attendant"
        },
        {
          text: "Pump",
          value: "pump"
        },
        {
          text: "Sales",
          value: "sales"
        },

        {
          text: "Actions",
          value: "actions"
        }
      ],
      paginate: {
        total: 0,
        page: 1,
        pages: 0,
        limit: 60
      },
      selectedPumpAssignedAttendants: []
    };
  },
  mixins: [DialogMixins],
  watch: {
    "paginate.page"(page) {
      let opt = {
        page,
        limit: this.paginate.limit
      };
      this.getAllPumpAttendants(opt);
    },
    "attendantToPump.date"(date) {
      this.$store.dispatch("pumps/alreadyAssignedPumpsByDate", { date });
    },
    assignedPumps(pumps) {
      if (pumps.length > 0) {
        const pumpIds = pumps.map(pump => pump?.pumpId?.id);
        const attendantIds = pumps.map(pump => pump?.attendantId?.id);
        this.pumps = this.pumps.filter(pump => !pumpIds.includes(pump?.id));
        this.pumpAttendants = this.pumpAttendants.filter(
          attendant => !attendantIds.includes(attendant?.id)
        );
      } else {
        this.pumps = this.copyPumps;
        this.pumpAttendants = this.copyPumpAttendants;
      }
    }
  },
  methods: {
    validateDate(date) {
      return moment(date).isAfter(moment());
    },
    openAddDialog() {
      this.dialog1 = true;
    },
    // close() {
    //   this.dialog1 = false;
    // },
    async assignAttendantToPumps() {
      try {
        this.isSaveLoading = true;
        const response = await Api().post(`/pump-attendants/assign/pumps`, {
          ...this.attendantToPump,
          attendantId: [this.attendantToPump?.attendantId]
        });
        if (response) {
          this.isSaveLoading = false;
          this.showSnackBar = true;
          this.message =
            response?.data?.message ??
            `Attendant assigned to pump successfully`;
          this.status = "success";
          this.attendantToPump.attendantId = [];
          this.attendantToPump.pumpId = "";
          this.dialog1 = false;
          await this.getAllPumpAttendants(this.paginate);
          setTimeout(() => (this.showSnackBar = false), 2000);
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = Array.isArray(e?.response?.data?.message)
          ? e?.response?.data?.message?.join(",")
          : `Unable to assign attendant to pump`;
        setTimeout(() => (this.showSnackBar = false), 2000);
        this.status = "red";
        this.dialog1 = false;
        this.isSaveLoading = false;
      }
    },
    async getAllPumpAttendants({ page, limit }) {
      try {
        this.isLoading = true;
        const response = await Api().get(
          `/pump-attendants/assign/pumps?isPaginated=true&page=${page}&limit=${limit}`
        );
        if (response) {
          const { docs, page, pages, total, limit } = response.data.paginateObj;
          this.paginate = { limit, page, pages, total };
          const observable$ = from(docs).pipe(
            groupBy(doc => doc.dateAssigned),
            mergeMap(group =>
              group.pipe(
                reduce(
                  (acc, cur) => {
                    acc.values.push(cur);
                    return acc;
                  },
                  { key: group.key, values: [] }
                )
              )
            ),
            toArray()
          );
          observable$.subscribe(docs => {
            this.assignedPumpAttendants = docs;
            this.isLoading = false;
          });
        }
      } catch (e) {
        this.isLoading = false;
        this.showSnackBar = true;
        this.message = e.response.data.message
          ? e.response.data.message
          : e.message;
        this.status = "red";
      }
    },
    async deleteAssignedPumpToAttendant() {
      try {
        this.isSaveLoading = true;
        const response = await Api().post(`/pumps/assigned/attendants/clear`, {
          pumpAssignAttendantId: this.selectedPumpAssignedAttendants
        });
        if (response) {
          this.isSaveLoading = false;
          this.showSnackBar = true;
          this.message =
            response?.data?.message ?? `Assignation deleted successfully`;
          this.status = "success";
          this.getAllPumpAttendants({ page: 1, limit: 60 });
          this.confirm = false;
          this.selectedPumpAssignedAttendants = [];
          setTimeout(() => (this.showSnackBar = false), 2000);
        }
      } catch (e) {
        const message = Array.isArray(e.response.data.message)
          ? e.response.data.message.join(",")
          : e.response.data.message;
        this.isSaveLoading = false;
        this.showSnackBar = true;
        setTimeout(() => (this.showSnackBar = false), 2000);
        this.message = message ?? e.message;
        this.status = "red";
      }
    }
  },
  async created() {
    try {
      this.isLoading = true;
      await this.getAllPumpAttendants({ page: 1, limit: 60 });
      const pumps = await Api().get(`/pumps?filter=true`);
      const pumpAttendants = await Api().get(`/pump-attendants?status=true`);
      if (pumps || pumpAttendants) {
        this.pumps = this.copyPumps = pumps.data;
        this.pumpAttendants = this.copyPumpAttendants = pumpAttendants.data;
      }
    } catch (e) {
      this.isLoading = false;
      this.showSnackBar = true;
      this.message = e.response.data.message
        ? e.response.data.message
        : e.message;
      this.status = "red";
    }
  }
};
</script>

<style scoped></style>
